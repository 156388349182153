import React, { useCallback, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { useLocalStorage } from './hooks/useLocalStorage';
import { useIsClient } from './hooks/useIsClient';
import { onCookieConsent } from './googleAnalytics';

export const CookieConsentBar: React.FC = () => {
  const router = useRouter();
  const raw = (router.query.raw as string) === '1';
  const isClient = useIsClient();
  const [alreadyConsented, setAlreadyConsented] = useLocalStorage<boolean>('cookieConsent', false);
  const onClick = useCallback(() => {
    setAlreadyConsented(true);
  }, [setAlreadyConsented]);
  useEffect(() => {
    if (!alreadyConsented || !isClient) {
      return;
    }

    onCookieConsent();
  }, [alreadyConsented, isClient]);

  if (alreadyConsented || !isClient || raw) {
    return null;
  }
  return (
    <div className="mx-auto my-2 cookie-consent">
      <Button onClick={onClick} color="primary" block>
        <FormattedMessage
          id="sml.web.sml.cookie-consent.enable"
          defaultMessage="This website uses cookies to enhance users experience. Please tap here to acknowledge that."
        />
      </Button>
    </div>
  );
};
