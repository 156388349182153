import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Col, Container, Row } from 'reactstrap';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { useRouter } from 'next/router';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { FooterLinks } from './FooterLinks';
import { nextAsset } from '../components/nextAsset';

export const SocialIcon: React.FC<{ href: string; icon: IconDefinition }> = ({ href, icon }) => {
  return (
    <a href={href} className="social-icon" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} />
    </a>
  );
};

export const Footer: React.FC<{ hideWaves?: boolean }> = ({ hideWaves }) => {
  const intl = useIntl();

  const router = useRouter();
  const raw = (router.query.raw as string) === '1';
  const companyLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'sml.web.footer.links.company.pricing', defaultMessage: 'Pricing' }),
        href: '/pricing'
      },
      {
        title: intl.formatMessage({ id: 'sml.web.footer.blog', defaultMessage: 'Blog' }),
        href: '/blog'
      },
      {
        title: intl.formatMessage({ id: 'sml.web.footer.faq', defaultMessage: 'FAQ' }),
        href: 'https://support.music24.com/helpdesk/'
      }
    ],
    [intl]
  );

  const whatWeDoLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'sml.web.smartlinks.types.artist.title',
          defaultMessage: 'Artist Bio Link'
        }),
        href: '/artist'
      },
      {
        title: intl.formatMessage({
          id: 'sml.web.smartlinks.types.release.title',
          defaultMessage: 'Release Link'
        }),
        href: '/release'
      },
      {
        title: intl.formatMessage({
          id: 'sml.web.smartlinks.types.playlist.title',
          defaultMessage: 'Playlist Link'
        }),
        href: '/playlist'
      },
      {
        title: intl.formatMessage({
          id: 'sml.web.smartlinks.footer.features',
          defaultMessage: 'Features'
        }),
        href: '/features'
      },
      {
        title: intl.formatMessage({
          id: 'sml.web.smartlinks.linkfire-alternative',
          defaultMessage: 'Linkfire alternative'
        }),
        href: '/linkfire-alternative'
      }
    ],
    [intl]
  );

  const otherProductsLinks = useMemo(
    () => [
      {
        title: 'Music Migrator',
        href: 'https://freeyourmusic.com'
      },
      {
        title: 'Quizado',
        href: 'https://quizado.com'
      },
      {
        title: 'GetPassbook',
        href: 'https://getpassbook.com'
      }
    ],
    []
  );
  const trustAndLegalLinks = useMemo(
    () => [
      {
        title: intl.formatMessage({
          defaultMessage: 'Privacy Policy',
          id: 'sml.web.footer.privacy-policy'
        }),
        href: '/privacy-policy'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Refund Policy',
          id: 'sml.web.footer.refund-policy'
        }),
        href: '/refund-policy'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Terms of Service',
          id: 'sml.web.footer.terms-of-service'
        }),
        href: '/terms-of-service'
      },
      {
        title: intl.formatMessage({
          defaultMessage: 'Imprint',
          id: 'sml.web.footer.imprint'
        }),
        href: '/imprint'
      }
    ],
    [intl]
  );
  const supportLinks = useMemo(
    () => [
      {
        title: 'support@music24.com',
        href: 'mailto:support@music24.com'
      },
      {
        title: intl.formatMessage({
          id: 'sml.web.footer.submit-ticket',
          defaultMessage: 'Submit an issue'
        }),
        href: 'https://support.music24.com/helpdesk/'
      }
    ],
    [intl]
  );
  if (raw) {
    return null;
  }
  return (
    <>
      {!hideWaves ? <div className="footer-waves" /> : <div className="py-5" />}
      <p className="mb-0 text-center">
        <img src={nextAsset('logos-sml/logo-full-light.svg')} alt="Music24 logo" />
      </p>
      <footer className="footer pb-5 pt-5">
        <Container>
          <Row className="text-center text-sm-start">
            <Col xs={6} sm={3} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'sml.web.footer.links.company.title',
                  defaultMessage: 'Company'
                })}
                links={companyLinks}
              />
            </Col>
            <Col xs={6} sm={3} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'sml.web.footer.links.what-we-do.title',
                  defaultMessage: 'What We Do'
                })}
                links={whatWeDoLinks}
              />
            </Col>
            <Col xs={6} sm={3} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'sml.web.footer.links.trust-and-legal.title',
                  defaultMessage: 'Trust & Legal'
                })}
                links={trustAndLegalLinks}
              />
            </Col>
            <Col xs={6} sm={3} className="pt-2">
              <FooterLinks
                title={intl.formatMessage({
                  id: 'sml.web.footer.links.support.title',
                  defaultMessage: 'Support'
                })}
                links={supportLinks}
              />
              <FooterLinks
                title={intl.formatMessage({
                  id: 'sml.web.footer.links.other-products.title',
                  defaultMessage: 'Other Products'
                })}
                links={otherProductsLinks}
              />
            </Col>
          </Row>
          <Row className="align-items-center flex-column-reverse flex-md-row ">
            <Col xs={12} md={6} className="text-center text-md-start mt-3 mt-md-0">
              <span className="footer-copyrights">
                <FormattedMessage
                  id="sml.web.sm-footer.rights"
                  defaultMessage="©{date} SmartLinksApp.com - part of FreeYourMusic.com"
                  values={{ date: new Date().getFullYear() }}
                />
              </span>
            </Col>
            <Col xs={12} md={6} className=" text-center text-md-end mb-3 mb-md-0">
              <SocialIcon href="https://twitter.com/smartlinksapp" icon={faTwitter} />
              <SocialIcon href="https://www.facebook.com/freeyourmusicapp" icon={faFacebook} />
              <SocialIcon href="https://www.youtube.com/channel/UCqqPiCbVgFOZwbckZq_2tug" icon={faYoutube} />
              <SocialIcon href="https://www.instagram.com/freeyourmusic/" icon={faInstagram} />
              <SocialIcon href="https://www.tiktok.com/@freeyourmusic" icon={faTiktok} />
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
