import React from 'react';
import qs from 'qs';
import { Button, ButtonProps } from 'reactstrap';

interface SignUpButtonProps extends ButtonProps {
  source: string;
  url: string;
  onClick?: () => void;
}

export const SignUpButton: React.FC<SignUpButtonProps> = React.memo(({ children, source, url, onClick, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button onClick={onClick} href={`/signup?${qs.stringify({ source, url })}`} {...props}>
      {children}
    </Button>
  );
});
