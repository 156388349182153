import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import padStart from 'lodash/padStart';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { config } from '../../../config/config';

const startDate = config.blackFriday.startsAt.getTime();
const endDate = config.blackFriday.endsAt.getTime();

export const BlackFridayHelloBar: React.FC = React.memo(() => {
  const intl = useIntl();
  const router = useRouter();
  const [timeLeft, setTimeLeft] = useState<{
    d: number;
    h: number;
    m: number;
    s: number;
    isRunning: boolean;
  }>({
    d: 0,
    h: 0,
    m: 0,
    s: 0,
    isRunning: false
  });
  const onTick = useCallback(() => {
    const now = new Date().getTime();
    const hasStarted = startDate < new Date().getTime();
    const secondsLeft = (endDate - now) / 1000;

    const days = Math.floor(secondsLeft / (60 * 60 * 24));

    const divisorForHours = secondsLeft % (60 * 60 * 24);
    const hours = Math.floor(divisorForHours / (60 * 60));

    const divisorForMinutes = divisorForHours % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    setTimeLeft({
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
      isRunning: hasStarted && secondsLeft >= 0
    });
  }, []);
  useEffect(() => {
    const timer = setInterval(onTick, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  const raw = (router.query.raw as string) === '1';
  const isPricing = useMemo(() => router.pathname.startsWith(`/pricing`), [router.pathname]);

  if (!timeLeft.isRunning || raw) {
    return null;
  }
  return (
    <Container className="promo-bar promo-bar-bf py-3 py-md-0" fluid>
      <Row className="justify-content-center align-items-center h-100">
        <Col className="h-100">
          <Container className="promo-bar-content h-100">
            <Row className="justify-content-center align-items-center  h-100">
              <Col>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center h-100">
                  <span>
                    {intl.formatMessage(
                      {
                        id: 'sml.web.promo-bar.description',
                        defaultMessage: '{promoName} SALE'
                      },
                      {
                        promoName: intl
                          .formatMessage({
                            id: 'sml.web.promo.black-friday',
                            defaultMessage: 'Black Friday'
                          })
                          .toUpperCase()
                      }
                    )}{' '}
                    <small className="text-gold m-2">
                      <FontAwesomeIcon icon={faBolt} />
                    </small>{' '}
                  </span>
                  <span className="fw-bold">
                    {intl.formatMessage(
                      {
                        id: 'sml.web.promo-bar.discount-description',
                        defaultMessage: '{discountValue} Off On All Plans'
                      },
                      {
                        discountValue: `${config.blackFriday.discountValue.toUpperCase()}%`
                      }
                    )}{' '}
                    <small className="text-gold m-2">
                      <FontAwesomeIcon icon={faBolt} />
                    </small>{' '}
                  </span>
                  <div>
                    <small>
                      {intl.formatMessage({
                        id: 'sml.web.promo-bar.ends-at',
                        defaultMessage: 'Offer Ends In:'
                      })}
                    </small>
                    <div className="timer-container m-2 my-4 my-md-2">
                      <div className="timer-container-box">
                        <div className="timer-container-value">{padStart(timeLeft.d.toString(), 2, '0')}</div>
                        <div className="timer-container-label">
                          {intl.formatMessage({
                            id: 'sml.web.timer.days',
                            defaultMessage: 'DAYS'
                          })}
                        </div>
                      </div>
                      <div className="timer-container-box">
                        <div className="timer-container-value">{padStart(timeLeft.h.toString(), 2, '0')}</div>
                        <div className="timer-container-label">
                          {intl.formatMessage({
                            id: 'sml.web.timer.hours',
                            defaultMessage: 'HRS'
                          })}
                        </div>
                      </div>
                      <div className="timer-container-box">
                        <div className="timer-container-value">{padStart(timeLeft.m.toString(), 2, '0')}</div>
                        <div className="timer-container-label">
                          {intl.formatMessage({
                            id: 'sml.web.timer.minutes',
                            defaultMessage: 'MINS'
                          })}
                        </div>
                      </div>
                      <div className="timer-container-box">
                        <div className="timer-container-value">{padStart(timeLeft.s.toString(), 2, '0')}</div>
                        <div className="timer-container-label">
                          {intl.formatMessage({
                            id: 'sml.web.timer.seconds',
                            defaultMessage: 'SECS'
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isPricing && (
                    <Link href="/pricing">
                      <Button size="lg" color="secondary" className="my-2 mx-2">
                        {intl.formatMessage(
                          {
                            id: 'sml.web.promo-bar.discount-cta',
                            defaultMessage: 'Get {discountValue} Off'
                          },
                          {
                            discountValue: `${config.blackFriday.discountValue.toUpperCase()}%`
                          }
                        )}
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});
