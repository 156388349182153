import React, { useCallback, useState } from 'react';
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { useRouter } from 'next/router';
import { BlackFridayHelloBar } from '../containers/pages/components/BlackFridayHelloBar';
import { config } from '../config/config';
import { nextAsset } from '../components/nextAsset';
import { SignUpButton } from '../containers/pages/components/SignUpButton';
import { CookieConsentBar } from '../components/CookieConsentBar';

const LogoImage: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <img
        src={nextAsset('logos-sml/logo-full-light-no-padding.svg')}
        style={{ maxHeight: 20 }}
        alt={intl.formatMessage({
          id: 'sml.web.nav.smartlinks.logo-alt',
          defaultMessage: 'Music24 by FreeYourMusic – Smart music sharing for DJs, influencers, and music curators'
        })}
        className="d-none d-lg-block"
      />
      <img
        src={nextAsset('logos-sml/logo.svg')}
        alt={intl.formatMessage({
          id: 'sml.web.nav.smartlinks.logo-alt',
          defaultMessage: 'Music24 by FreeYourMusic – Smart music sharing for DJs, influencers, and music curators'
        })}
        className="d-block d-lg-none"
      />
    </>
  );
};

export const NavbarLogo: React.FC = () => {
  return (
    <Link href="/">
      <a className="nav-brand mx-auto">
        <LogoImage />
      </a>
    </Link>
  );
};

export const MainNavbar: React.FC = React.memo(() => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => {
    setIsOpen((open: boolean) => !open);
  }, []);

  return (
    <>
      <BlackFridayHelloBar />
      <CookieConsentBar />
      <Navbar expand="lg" className={classNames('pt-3  mainNavbar')} container fixed="top">
        <div className="d-none d-lg-flex w-100">
          <NavbarBrand className="mx-auto me-lg-0 d-flex align-items-center" tag={NavbarLogo} />
          <Collapse isOpen={isOpen} navbar className="justify-content-around ">
            <Nav navbar className={classNames('order-lg-2 justify-content-center')}>
              <NavItem className="pe-2">
                <Button onClick={close} href={`${config.smartlinksAppDomain}/login`} outline color="primary">
                  <FormattedMessage id="sml.web.playlists-sharing.login" defaultMessage="Log In" />
                </Button>
              </NavItem>
              <NavItem className="ps-2">
                <SignUpButton onClick={close} source="navbar" url={router.asPath} color="primary">
                  <FormattedMessage id="sml.web.playlists-sharing.start-testing" defaultMessage="Sign Up" />
                </SignUpButton>
              </NavItem>
            </Nav>
            <Nav navbar className="px-2 flex-grow-1 d-flex justify-content-center">
              <UncontrolledDropdown nav inNavbar className="px-2 d-none d-lg-block">
                <DropdownToggle nav caret className="fw-bold">
                  <FormattedMessage id="sml.web.nav.smart_links.promote" defaultMessage="Promote" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <Link href="/artist" passHref key="artist">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.artist" defaultMessage="Artist Bio Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link href="/release" passHref key="release">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.release" defaultMessage="Release Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link href="/playlist" passHref key="playlist">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.playlist" defaultMessage="Playlist Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <div className="d-block d-lg-none">
                <NavItem>
                  <Link href="/artist" passHref key="artist">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.artist" defaultMessage="Artist Bio Link" />
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href="/release" passHref key="release">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.release" defaultMessage="Release Link" />
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href="/playlist" passHref key="playlist">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.playlist" defaultMessage="Playlist Link" />
                    </NavLink>
                  </Link>
                </NavItem>
              </div>
              <NavItem className="px-lg-2">
                <Link href="/features" passHref key="features">
                  <NavLink onClick={close}>
                    <FormattedMessage id="sml.web.nav.features" defaultMessage="Features" />
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-lg-2">
                <Link href="/pricing" passHref key="pricing">
                  <NavLink onClick={close}>
                    <FormattedMessage id="sml.web.nav.pricing" defaultMessage="Pricing" />
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-lg-2">
                <Link href="/blog" passHref key="blog">
                  <NavLink onClick={close}>
                    <FormattedMessage id="sml.web.nav.blog" defaultMessage="Blog" />
                  </NavLink>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div className="d-flex d-lg-none w-100">
          <Button onClick={toggle} color="transparent" className="navbar-toggle-custom">
            <FontAwesomeIcon icon={faBars} size="2x" />
          </Button>
          <NavbarBrand className="mx-auto me-lg-0 d-flex align-items-center" tag={NavbarLogo} />
          <Collapse
            isOpen={isOpen}
            navbar
            className="justify-content-around position-absolute bg-white px-4 pb-4"
            style={{ top: 76, right: 0, left: 0 }}
          >
            <Nav navbar className="px-2 flex-grow-1 d-flex justify-content-center">
              <UncontrolledDropdown nav inNavbar className="px-2 d-none d-lg-block">
                <DropdownToggle nav caret className="fw-bold">
                  <FormattedMessage id="sml.web.nav.smart_links.promote" defaultMessage="Promote" />
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <Link href="/artist" passHref key="artist">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.artist" defaultMessage="Artist Bio Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link href="/release" passHref key="release">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.release" defaultMessage="Release Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link href="/playlist" passHref key="playlist">
                      <NavLink onClick={close}>
                        <FormattedMessage id="sml.web.nav.smart_links.playlist" defaultMessage="Playlist Link" />
                      </NavLink>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <div className="d-block d-lg-none">
                <NavItem>
                  <Link href="/artist" passHref key="artist">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.artist" defaultMessage="Artist Bio Link" />
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href="/release" passHref key="release">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.release" defaultMessage="Release Link" />
                    </NavLink>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href="/playlist" passHref key="playlist">
                    <NavLink onClick={close}>
                      <FormattedMessage id="sml.web.nav.smart_links.playlist" defaultMessage="Playlist Link" />
                    </NavLink>
                  </Link>
                </NavItem>
              </div>
              <NavItem className="px-lg-2">
                <Link href="/pricing" passHref key="pricing">
                  <NavLink onClick={close}>
                    <FormattedMessage id="sml.web.nav.pricing" defaultMessage="Pricing" />
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-lg-2">
                <Link href="/blog" passHref key="blog">
                  <NavLink onClick={close}>
                    <FormattedMessage id="sml.web.nav.blog" defaultMessage="Blog" />
                  </NavLink>
                </Link>
              </NavItem>
            </Nav>
            <Nav navbar className="d-flex d-lg-none justify-content-center">
              <Row>
                <NavItem className="pe-4 py-4">
                  <Button
                    onClick={close}
                    href={`${config.smartlinksAppDomain}/login`}
                    outline
                    color="primary"
                    className="mb-1"
                  >
                    <FormattedMessage id="sml.web.playlists-sharing.login" defaultMessage="Log In" />
                  </Button>{' '}
                  <Button
                    className="mb-1"
                    onClick={close}
                    href={`${config.smartlinksAppDomain}/signup`}
                    color="primary"
                  >
                    <FormattedMessage id="sml.web.playlists-sharing.start-testing" defaultMessage="Sign Up" />
                  </Button>
                </NavItem>
              </Row>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
});
