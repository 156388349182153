import React from 'react';
import Link from 'next/link';

export interface FooterLink {
  title: string;
  href: string;
  as?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FooterLinkElement: React.FC<FooterLink> = ({ title, href, as, onClick }) => {
  return (
    <li className="footer-link my-1">
      {href.startsWith('http') ? (
        <a onClick={onClick} href={href}>
          {title}
        </a>
      ) : (
        <Link href={href} as={as} passHref>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={onClick}>{title}</a>
        </Link>
      )}
    </li>
  );
};
export const FooterLinks: React.FC<{ title: string; links: FooterLink[] }> = ({ title, links }) => {
  return (
    <div className="footer-links">
      <div className="footer-links-title">{title}</div>
      <ul className="list-unstyled footer-links d-flex flex-column justify-content-center mflex-wrap">
        {links.map((link) => (
          <FooterLinkElement title={link.title} href={link.href} key={link.title} onClick={link.onClick} as={link.as} />
        ))}
      </ul>
    </div>
  );
};
